define("@agari/ember-shared/utils/time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.YEAR = _exports.WEEK = _exports.TO_MILLISECOND = _exports.SECOND = _exports.MONTH = _exports.MINUTE = _exports.HOUR = _exports.DAYS_IN_A_YEAR = _exports.DAY = void 0;
  var TO_MILLISECOND = 1000;
  _exports.TO_MILLISECOND = TO_MILLISECOND;
  var SECOND = 1;
  _exports.SECOND = SECOND;
  var MINUTE = SECOND * 60;
  _exports.MINUTE = MINUTE;
  var HOUR = MINUTE * 60;
  _exports.HOUR = HOUR;
  var DAY = HOUR * 24;
  _exports.DAY = DAY;
  var WEEK = DAY * 7;
  _exports.WEEK = WEEK;
  var MONTH = DAY * 30; // approximately.
  _exports.MONTH = MONTH;
  var YEAR = DAY * 365; // not leap year...
  _exports.YEAR = YEAR;
  var DAYS_IN_A_YEAR = 365.0;
  _exports.DAYS_IN_A_YEAR = DAYS_IN_A_YEAR;
});