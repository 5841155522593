define("@agari/ember-shared/mixins/route-modal-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    previousOverflow: "",
    actions: {
      showModal: function showModal(config) {
        var bodyOverflowStyle = window.document.getElementsByTagName("body")[0].style.overflow;
        this.set("previousOverflow", bodyOverflowStyle);
        // add style to <body> element to prevent background scrolling
        window.document.getElementsByTagName("body")[0].style.overflow = "hidden";
        var controller = this.controllerFor("dialogs");
        controller.set("component", "dialogs/".concat(config["component"] || "simple-message"));
        controller.set("options", config["options"] || {});
        this.render("modal-dialog", {
          into: "application",
          outlet: "modal",
          model: config["model"],
          controller: controller
        });
      },
      removeModal: function removeModal() {
        // reset style to original value
        window.document.getElementsByTagName("body")[0].style.overflow = this.previousOverflow;
        // disconnect the outlet, calling disconnectOutlet when the outlet was displaying an
        // object that has been destroyed causes an error to be thrown.
        // see https://github.com/emberjs/ember-inspector/issues/625
        this.render("empty", {
          outlet: "modal",
          into: "application",
          controller: "application"
        });
        var controller = this.controllerFor("dialogs");
        controller.setProperties({
          model: null,
          component: null,
          options: null
        });
      },
      willTransition: function willTransition() {
        this.send("removeModal");
        this._super(arguments);
      }
    }
  });
  _exports.default = _default;
});