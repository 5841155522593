define("@agari/ember-shared/components/dialogs", ["exports", "ember-animated/transitions/move-over"], function (_exports, _moveOver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * Base component for modals.
  
  * @class DialogComponent
  * @extends {Component}
  *
  */
  var _default = Ember.Component.extend({
    layoutName: "components/modal-dialog",
    /**
      String to use as modal title.
       e.g. `"My Modal"`
       @argument title
      @type {string}
    */
    title: "",
    /**
      String to display on the OK button. Defaults to `"OK"`.
       @argument okLabel
      @type {string}
    */
    okLabel: "OK",
    /**
      Boolean to disable the OK button during pending transactions.
      Prevents multiple submissions. Defaults to `false`.
       @argument okInProgress
      @type {boolean}
    */
    okInProgress: false,
    /**
      String to display on the Cancel button. Defaults to `"Cancel"`.
       @argument cancelLabel
      @type {string}
    */
    cancelLabel: "Cancel",
    /**
      Boolean to control closing if the user clicks outside the modal. Defaults to `false`.
       @argument closeOnClickOutside
      @type {boolean}
    */
    closeOnClickOutside: false,
    /**
      Boolean to show the OK button. Defaults to `true`.
       @argument hasOK
      @type {boolean}
    */
    hasOk: true,
    /**
      Boolean to disable the OK button. Defaults to `false`.
       @argument disableOkButton
      @type {boolean}
    */
    disableOkButton: false,
    /**
      Boolean to show a Cancel button. Defaults to `true`.
       @argument hasCancel
      @type {boolean}
    */
    hasCancel: true,
    /**
      Boolean to show a Close X button. Defaults to `true`.
       @argument hasClose
      @type {boolean}
    */
    hasClose: true,
    /**
      Function to call when the user clicks the "next" button.
      The button will only appear if this function is not null.
       @argument options.getNext
      @type {function}
     */

    /**
      Function to call when the user clicks the "previous" button.
      The button will only appear if this function is not null.
       @argument options.getPrevious
      @type {function}
     */

    hasCarousel: Ember.computed.or("options.getPrevious", "options.getNext"),
    carouselActive: false,
    carouselDirection: null,
    carouselTransition: Ember.computed("carouselActive", "carouselDirection", function () {
      var transition = this.carouselDirection === "next" ? _moveOver.toLeft : _moveOver.toRight;
      return this.carouselActive ? transition : null;
    }),
    /**
      String that describes the path to the template file to use for this dialog.
      Defaults to `dialogs/<name-of-component>`
       @argument innerTemplate
      @type {string}
    */
    innerTemplate: Ember.computed(function () {
      // We allow innerTemplate to be whatever you'd like, but it defaults to
      // dialogs/$name_of_component
      return this.toString().split(":")[1];
    }),
    modalClass: Ember.computed(function () {
      // We allow innerTemplate to be whatever you'd like, but it defaults to
      // $name_of_component
      return this.toString().split(":")[1].split("/")[1];
    }),
    /**
      Action to invoke when the OK, Cancel, or close buttons are clicked.
       @argument defaultAction
      @type {function}
    */
    didInsertElement: function didInsertElement() {
      this.set("modalContentHeight", this.element.querySelector(".modal-content").offsetHeight);

      // if the viewport is smaller than MODAL_MIN_HEIGHT position this element to be
      // absolutely positioned. Offset of either the top or bottom.  Top in most cases, unless
      // the viewport is is too close to the bottom of the document.
      var documentHeight = Math.max(document.body.offsetHeight, document.body.scrollHeight, document.documentElement.clientHeight, document.documentElement.offsetHeight, document.documentElement.scrollHeight);
      var viewportHeight = window.innerHeight;
      var viewportOffset = document.documentElement && document.documentElement.scrollTop || document.body.scrollTop;
      var overlay = this.element.querySelector("#modal-dialog");
      var modal = this.element.querySelector("#modal-dialog > div");
      var styles = window.getComputedStyle(this.element);
      var height = this.element.offsetHeight;
      var borderTopWidth = parseFloat(styles.borderTopWidth);
      var borderBottomWidth = parseFloat(styles.borderBottomWidth);
      var paddingTop = parseFloat(styles.paddingTop);
      var paddingBottom = parseFloat(styles.paddingBottom);
      var modalHeight = height - borderBottomWidth - borderTopWidth - paddingTop - paddingBottom;
      var documentModalDiff = documentHeight - modalHeight;
      var viewportModalDiff = modalHeight - viewportHeight;
      if (viewportModalDiff > 0) {
        // Is the viewport close to the bottom? and Is the document taller than the modal?
        if (documentHeight - viewportOffset < modalHeight && documentModalDiff > 0) {
          // modal is near the bottom of the document, but the document is longer than the modal
          // therefore position based on bottom
          modal.style.top = documentModalDiff;

          // overlay can be set to bottom of document
          overlay.style.bottom = 0;
        } else {
          // modal is larger than the document
          // position modal from top
          modal.style.top = viewportOffset;

          // overlay needs to be set to just pass the bottom of the modal (which extends out of
          // the document)
          overlay.style.bottom = -viewportModalDiff;
        }
      } else {
        // overlay can be set to bottom of document
        overlay.style.bottom = 0;
      }
    },
    actions: {
      onClickOutside: function onClickOutside() {
        if (this.closeOnClickOutside) {
          this.send("modalOk");
          this.defaultAction();
        }
      },
      modalOk: function modalOk() {},
      _modalOk: function _modalOk() {
        this.send("modalOk");
        this.defaultAction();
      },
      modalCancel: function modalCancel() {},
      _modalCancel: function _modalCancel() {
        this.send("modalCancel");
        this.defaultAction();
      },
      getNext: function getNext() {
        if (this.options.getNext) {
          this.setProperties({
            carouselActive: true,
            carouselDirection: "next"
          });
          this.options.getNext();
        }
      },
      getPrevious: function getPrevious() {
        if (this.options.getPrevious) {
          this.setProperties({
            carouselActive: true,
            carouselDirection: "previous"
          });
          this.options.getPrevious();
        }
      }
    }
  });
  _exports.default = _default;
});