define("@agari/ember-shared/controllers/dialogs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      cleanUpModal: function cleanUpModal() {
        this.send("removeModal");
      }
    }
  });
  _exports.default = _default;
});