define("@agari/ember-shared/components/sortable-pill-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * A component that can sort strings in an array by dragging and dropping
  * and can remove strings from the array
  
  ```hbs
    {{sortable-pill-group
      model=stringList
      reorderItems=(action 'reorderItems')
      removeItem=(action 'removeItem')
    }}
  ```
  *
  * @class SortablePillGroup
  * @extends {Component}
  */
  var _default = Ember.Component.extend({
    classNames: ["sortable-pill-group"],
    /**
      Array of strings to be displayed and managed.
      @argument model
      @type {Array}
    */
    /**
      Action called when string is dragged and dropped. Receives array of strings.
      @argument reorderItems
      @type {function}
    */
    /**
      Action called when string is removed. Receives index of removed string.
      @argument removeItem
      @type {function}
    */
    actions: {
      reorder: function reorder(nextItems) {
        this.reorderItems(nextItems);
      },
      remove: function remove(index) {
        this.removeItem(index);
      }
    }
  });
  _exports.default = _default;
});