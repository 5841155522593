define("@agari/ember-shared/cousteauConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable */
  /*
   * !! DO NOT EDIT THIS FILE MANUALLY !!
   *
   * This file is generated in Cousteau using: `rake ember:generate_constants`
   *
   */
  var _default = {
    COLOR: {
      'likely_good': "#FFDD7E",
      'no_auth': "#878787",
      'blocked': "#ca453e",
      'agari_orange': "#FF9938",
      'dashboard_column': '#FEEDBF',
      'another_grey': '#D6D6D6'
    },
    PRODUCT_VERSION: {
      PRODUCTS: ["ep", "apd", "ep-pdf", "air"]
    },
    AUTH: {
      ABSOLUTE_RETURN_HOSTS: ["air-stage.agari.com", "air.agari.com", "apr-stage.agari.com", "apr.agari.com"]
    },
    SSO: {
      NAME_ID_FORMATS: ["urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified", "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress", "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent"]
    },
    RECEIVER_DOMAIN: {
      REPUTATION_MIN: 0.0,
      REPUTATION_MAX: 10.0
    },
    SENDER: {
      SENDER_APPROVED: 'approved',
      SENDER_DENIED: 'denied',
      SENDER_UNAPPROVED: 'unapproved',
      SCORING_REASON_MANUAL: 'manual',
      SCORING_REASON_AUTHENTICATED: 'authenticated',
      SCORING_REASON_MODEL: 'model'
    },
    MESSAGE: {
      AUTH_MIN: 0.0,
      AUTH_BOUNDARY: 0.5,
      AUTH_MAX: 1.0,
      RISK_MIN: 0.0,
      RISK_MAX: 10.0,
      REVIEWED: 'reviewed',
      NOT_REVIEWED: 'not_reviewed',
      REPORTED: 'reported',
      DMARC_PASS: 'pass',
      DMARC_UNKNOWN: 'unknown',
      DMARC_FAIL: 'fail',
      CAMPAIGN_COMPOUND_DELIM: '|3s_d3l1m|',
      ELASTICSEARCH_MAX: 10000,
      AGARI_ALERTING_IP: '198.2.132.180',
      ENFORCEMENT: {
        PENDING: 'pending',
        SUCCESS: 'success',
        ERROR: 'error'
      },
      ATTACK_TYPE_METRICS: ["address_group", "compromised_webmail", "dni", "lookalike_domain", "spoof"],
      MALICIOUS_ATTACHMENT_METRICS: ["likely_malicious_uri", "malicious_attachments"],
      ALL_ATTACK_TYPE_METRICS: ["address_group", "compromised_webmail", "dni", "likely_malicious_uri", "lookalike_domain", "malicious_attachments", "spoof"],
      TRUST_CLASSIFICATION: {
        UNTRUSTED: 'untrusted',
        SUSPICIOUS: 'suspicious',
        TRUSTED: 'trusted'
      },
      DIRECTIONALITY: {
        INBOUND: 'inbound',
        OUTBOUND: 'outbound',
        INTERNAL: 'internal'
      },
      EXPORT_PER_PAGE: 50,
      EXPORT_MAX: 10000
    },
    RECEIVER_IP: {
      SBRS_MIN: -10.0,
      SBRS_MAX: 10.0
    },
    MESSAGE_AGGREGATE: {
      QUERY_TYPE: {
        TOP: 'top',
        SPOOF: 'spoof',
        ADDRESS_GROUP: 'address_group',
        DNI: 'dni',
        COMPROMISED_WEBMAIL: 'compromised_webmail',
        LOOKALIKE_DOMAIN: 'lookalike_domain',
        LOW_TRUST: 'low_trust',
        ENFORCED: 'enforced',
        MALICIOUS_ATTACHMENTS: 'malicious_attachments',
        LIKELY_MALICIOUS_URI: 'likely_malicious_uri'
      }
    },
    DEFAULT: {
      INTERVAL_DAYS: 1,
      MAX_INTERVAL_DAYS: 61,
      LIMIT: 25,
      OFFSET: 0
    },
    ORGANIZATION: {
      BRANDS: {
        AGARI: 'Agari',
        CISCO: 'Cisco'
      },
      RAPID_DMARC_NAME: 'Rapid DMARC',
      COHORT_THRESHOLD_MINIMUM: 5,
      AFFILIATE_SERVICE_LEVELS: {
        EVAL: 'eval',
        SUBSCRIBER: 'subscriber'
      },
      SERVICE_LEVELS: {
        EVAL: 'eval',
        SUBSCRIBER: 'subscriber',
        PARTNER: 'partner',
        INTERNAL: 'internal'
      },
      MAIL_INFRA: {
        GMAIL: 'gmail',
        OFFICE: 'office',
        MS_EXCHANGE: 'ms_exchange',
        OTHER: 'other',
        HYBRID: 'hybrid'
      },
      SEG_OPTIONS: ["Cisco", "Proofpoint", "Symantec", "Mimecast", "EOP", "Google", "Other"],
      SEG_TYPE_OPTIONS: ["Cloud", "On-Prem"],
      REGIONS: ["North America", "EMEA", "APAC"],
      INDUSTRIES: ["Finance", "Government", "Healthcare", "Other", "Retail", "Technology"],
      MAILBOX_COUNT_OPTIONS: ["0-250", "250-1K", "1,001-3K", "3,001-5K", "5,001-10K", "10,001-25K", "25,001-50K", "50,001-100K", "100K+"],
      BREACH_COST_BY_INDUSTRY: {
        FINANCE: 'FINANCE',
        HEALTHCARE: 'HEALTHCARE',
        GOVERNMENT: 'GOVERNMENT',
        RETAIL: 'RETAIL',
        TECHNOLOGY: 'TECHNOLOGY',
        NOT_SET: 'NOT_SET'
      },
      MAILBOX_COUNT_ACTUAL_DEFAULTS: [125, 625, 2000, 4000, 7500, 17500, 37500, 75000, 100000]
    },
    COLLECTOR: {
      ENFORCEMENT_STATE: {
        ENABLED: 'enabled',
        DISABLED: 'disabled',
        PENDING: 'pending',
        ERROR: 'error'
      }
    },
    COLLECTOR_CONFIGURATION: {
      MAX_URI_DATA_SIZE: 512000,
      COLLECT_URIS_LIMIT_DEFAULT: 100,
      COLLECT_URIS_TRUNCATE_CHARACTERS_DEFAULT: 1024
    },
    COLLECTOR_NURSE: {
      MIN_HEALTH_CHECK_SAMPLES: 5,
      WARN_THRESHOLD_PERCENT: 80,
      PHONE_HOME_DEGRADE_WINDOW: 600,
      STALE_UPLOADS_FACTOR: 4.25,
      EMAIL_LOG_INTERVAL_SECONDS: 900,
      DEGRADER_EMAIL_LOG_STALE_PERIOD_SECONDS: 3825.0,
      NURSE_QUEUE_BACKUP_SIZE: 20,
      DEGRADER_CHECK_PERIOD_SECONDS: 120
    },
    ON_DEMAND_POLICY: {
      PENDING: 'pending',
      IN_PROGRESS: 'in_progress',
      COMPLETE: 'complete',
      MAX_ENFORCEABLE_MESSAGES: 2000,
      MAX_AGE: 60
    },
    ALERT_ACTION: {
      ENFORCEMENT_ACTIONS: {
        DELIVER: 'deliver',
        MARK_SPAM: 'mark_spam',
        MOVE: 'move',
        INBOX: 'inbox',
        DELETE: 'delete',
        JUNK: 'junk',
        QUARANTINE: 'quarantine'
      }
    },
    ADDRESS_GROUP: {
      MEMBER_MAX: 1000,
      AD_LINKAGE: {
        NATIVE: 'native',
        LINKED: 'linked',
        DELINKED: 'delinked',
        ORPHANED: 'orphaned'
      },
      ERROR: {
        NOT_FOUND: 'not_found',
        INVALID: 'invalid'
      }
    },
    DOMAIN: {
      DOMAIN_NAME_MAX: 253,
      RISK_LEVELS: {
        LOWEST: 'lowest',
        LOW: 'low',
        MEDIUM: 'medium',
        HIGH: 'high',
        HIGHEST: 'highest'
      }
    },
    COUSTEAU_REPORT: {
      AGG_LEVEL_LIMIT: {
        DAY: 'day',
        WEEK: 'week'
      },
      ATTACK_RATE_PREF_KEYS: ["compare_additional_on", "compare_additional_size", "compare_additional_industry", "compare_additional_region"]
    },
    INVESTIGATION_REPORTING: {
      CURRENCIES: ["EUR", "GBP", "USD"],
      INDUSTRY: {
        BREACH_COST: 'breach_cost',
        BREACH_RISK_FACTOR: 'breach_risk_factor',
        CURRENCY: 'currency',
        DWELL_TIME: 'dwell_time',
        MALICIOUS_INVESTIGATION_TIME: 'malicious_investigation_time',
        NON_MALICIOUS_INVESTIGATION_TIME: 'non_malicious_investigation_time',
        SOC_ANALYST_COST: 'soc_analyst_cost'
      }
    },
    INVESTIGATION: {
      CLASSIFICATIONS: {
        BENIGN: 'benign',
        MALICIOUS: 'malicious',
        SIMULATION: 'simulation',
        SPAM: 'spam',
        UNKNOWN: 'unknown'
      },
      PRIORITIES: {
        CRITICAL: 'critical',
        HIGH: 'high',
        MEDIUM: 'medium',
        LOW: 'low'
      },
      STATES: {
        ACTIVE: 'active',
        CLOSED: 'closed',
        NEW: 'new',
        TRIAGED: 'triaged'
      },
      SOURCE_TYPES: {
        CDR: 'cdr',
        PHISH_REPORT: 'phish_report'
      },
      AIR_SUBMISSION_ADDRESSES: {
        DEVELOPMENT: 'development',
        PRODUCTION: 'production',
        STAGING: 'staging'
      }
    },
    ATTACHMENT_SCAN_RESULT: {
      SANDBOX_STATUS: {
        PENDING: 'pending',
        ERROR: 'error',
        COMPLETE: 'complete'
      }
    },
    THREAT_DASHBOARD_PREFS: {
      ATTACK_TYPE_COST: 159469,
      ATTACK_TYPE_PROBABILITY_INVERSE: 270,
      AVERAGE_BREACH_COST: 7900000,
      AVERAGE_EMPLOYEE_HOURLY_RATE: 50,
      BEC_ATTACK_PERCENTAGE: 24,
      CURRENCY: 'USD',
      EMAIL_BREACH_PERCENTAGE: 96,
      INCLUDE_BREACH_COST: true,
      NEW_BREACH_PROBABILITY: 14,
      SECONDS_LOST_PER_SPAM_MESSAGE: 10,
      SECONDS_TO_REMEDIATE_MESSAGE: 10,
      ENGINEER_HOURLY_RATE: 50
    },
    CDR_CAMPAIGN: {
      IOC_TYPES: {
        AIR: 'air',
        ACID: 'acid'
      },
      STATES: {
        ARCHIVED: 'archived',
        NEW: 'new',
        APPROVED: 'approved',
        PUBLISHED: 'published',
        EXPIRED: 'expired'
      }
    },
    CDR_SOURCES: {
      ACID: {
        LABEL: 'Agari BEC Feed',
        IMAGE: '/assets/images/brand/acid_logo_150px.png'
      },
      AIR_AUTO_APPROVED: {
        LABEL: 'Agari SOC Network',
        IMAGE: '/assets/images/brand/agari_logo_150px.png'
      },
      AIR: {
        LABEL: 'Agari SOC Network',
        IMAGE: '/assets/images/brand/agari_logo_150px.png'
      }
    },
    CDR_EVENT: {
      STATES: {
        ACTIVE: 'active',
        INACTIVE: 'inactive'
      },
      ACTIONS: {
        DELETE: 'delete',
        QUARANTINE: 'quarantine',
        MONITOR: 'monitor'
      }
    },
    CDR_SEARCH: {
      IOC_SOURCE_ATTRIBUTE: 'ioc_source',
      IOC_SOURCE: {
        IOC_FEED: 'ioc_feed',
        ACID_APPROVED: 'acid_approved'
      }
    },
    ORG_COLLECTOR_CONFIGURATION: {
      O365_WHITELIST_IPS: ["23.103.132.0/22", "23.103.136.0/21", "23.103.144.0/20", "23.103.198.0/23", "23.103.200.0/22", "40.92.0.0/14", "40.107.0.0/17", "52.100.0.0/14", "52.238.78.88", "65.55.88.0/24", "65.55.169.0/24", "94.245.120.64/26", "104.47.0.0/17", "157.55.234.0/24", "157.56.110.0/23", "157.56.112.0/24", "207.46.100.0/24", "207.46.163.0/24", "213.199.154.0/24", "213.199.180.128/26", "216.32.180.0/23", "2a01:111:f400:7c00::/54", "2a01:111:f400:fc00::/54", "2a01:111:f403::/48"]
    },
    LEGAL_AGREEMENT: {
      MOBILE_EULA_ADDENDUM: 'agari-mobile-sec-eula'
    },
    SCAN_RESULT: {
      SANDBOX_STATUS: {
        COMPLETE: 'complete',
        PENDING: 'pending',
        ERROR: 'error'
      },
      SANDBOX_VERDICT: {
        CLEAN: 'clean',
        MALICIOUS: 'malicious',
        SUSPICIOUS: 'suspicious'
      },
      SCAN_STATUS: {
        COMPLETE: 'complete',
        PENDING: 'pending'
      },
      SCAN_VERDICT: {
        CLEAN: 'clean',
        MALICIOUS: 'malicious',
        WHITELIST: 'whitelist'
      }
    },
    EXTERNAL_SERVICE_STATUS: {
      ERROR: 'error',
      OK: 'ok'
    },
    ORGANIZATION_SKIP_RULE: {
      SKIP_RULE_TYPE: {
        SENDING_IP: 'sending_ip',
        FROM_ADDRESS: 'from_address',
        FROM_ADDRESS_DOMAIN: 'from_address_domain',
        TO_ADDRESS: 'to_address',
        TO_ADDRESS_DOMAIN: 'to_address_domain',
        MAIL_FROM_DOMAIN: 'mail_from_domain',
        SUBJECT: 'subject',
        SCL_VALUE: 'scl_value',
        X_HEADER_EXISTENCE: 'x_header_existence',
        X_HEADER_VALUE: 'x_header_value'
      },
      SCL_VALUE_DEFAULT: '5',
      DIRECTIONALITY_DEFAULT: ["inbound"]
    }
  };
  _exports.default = _default;
});