define("ember-infinity/templates/components/infinity-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b/UaeUPX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",false],[14,\"class\",[32,[[27,[24,0],[\"loaderClassNames\"]],[31,113,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"viewportEntered\"]],\" in-viewport\"],null],[31,155,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"isDoneLoading\"]],\" reached-infinity\"],null]]],null],[23,\"data-test-infinity-loader\",\"\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[\"didInsertLoader\"]],[27,[24,0],[]]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"      \"],[16,1,[[27,[24,0],[\"infinityModelContent\"]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"isDoneLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"span\",true],[10],[1,0,0,0,[27,[24,0],[\"loadedText\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"        \"],[9,\"span\",true],[10],[1,0,0,0,[27,[24,0],[\"loadingText\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\"]}",
    "meta": {
      "moduleName": "ember-infinity/templates/components/infinity-loader.hbs"
    }
  });
  _exports.default = _default;
});