define("@agari/ember-shared/helpers/abbrev-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abbrevNumber = abbrevNumber;
  _exports.default = void 0;
  /**
   * abbreviates a numeric value into a friendly string
   * @module abbrevNumber
   * @param {Number, String} num value to format
   * @return {String} abbreviated value
   **/
  function abbrevNumber(params) {
    var val = params[0] || 0;
    if (typeof val !== "number") val = parseInt(val, 10);
    var mag = 0;
    if (val >= 1000) {
      while (val >= 1000) {
        val = val / 1000;
        mag++;
      }
      if (val >= 100) {
        val = val.toFixed(0);
      } else if (val >= 10) {
        val = val.toFixed(1);
      } else {
        val = val.toFixed(2);
      }
    }
    var retval = val.toString();
    switch (mag) {
      case 1:
        retval += " K";
        break;
      case 2:
        retval += " M";
        break;
      case 3:
        retval += " B";
        break;
    }
    return retval;
  }
  var _default = Ember.Helper.helper(abbrevNumber);
  _exports.default = _default;
});