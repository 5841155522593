define("@agari/ember-shared/helpers/format-rounded-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatRoundedPercentage = formatRoundedPercentage;
  function formatRoundedPercentage(params) {
    var formatted_value;
    var value = params[0] * 100;
    if (value > 0 && value < 1) {
      formatted_value = "< 1";
    } else {
      formatted_value = Math.round(value);
    }
    return formatted_value + "%";
  }
  var _default = Ember.Helper.helper(formatRoundedPercentage);
  _exports.default = _default;
});