define("@agari/ember-shared/register-shared-routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = registerSharedRoutes;
  function registerSharedRoutes(router) {
    router.map(function () {
      // These routes are used to create
      // the path for the absolute links across
      // products. They are intended to be overwritten
      // in each of the apps' routers.

      // APR routes
      this.route("acid");
      this.route("admin", function () {
        this.route("domains");
      });
      this.route("analyst");
      this.route("executive");
      this.route("settings", {
        path: "settings/:organization_id"
      }, function () {
        this.route("configuration");
      });

      // APD routes

      this.route("address-groups");
      this.route("alerts");
      this.route("adjustments");
      this.route("continuous-detection");
      this.route("dashboard");
      this.route("messages");
      this.route("receiver-domains");
      this.route("receiver-ips");
      this.route("organizations");
      this.route("organization", {
        path: "organization/:organization_id"
      });
      this.route("reports");
      this.route("senders");
      this.route("sensors");
      this.route("users");
      this.route("user_responses");
    });
  }
});