define("@agari/ember-shared/helpers/empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.empty = empty;
  function empty(params) {
    if (params[0]) {
      return !params[0].length;
    } else {
      return false;
    }
  }
  var _default = Ember.Helper.helper(empty);
  _exports.default = _default;
});