define("@agari/ember-shared/helpers/concatData", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.concatData = void 0;
  var concatData = function concatData(params) {
    return params.reduce(function (prev, item) {
      return prev + String(item);
    }, "");
  };
  _exports.concatData = concatData;
  var _default = Ember.Helper.helper(concatData);
  _exports.default = _default;
});