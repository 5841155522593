define("@agari/ember-shared/helpers/format-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatPercentage = formatPercentage;
  function formatPercentage(params) {
    var value = params[0];
    var fixed = params.length > 1 ? params[1] : 2;
    if (fixed === 0) {
      return Math.trunc(value * 100) + "%";
    }
    return (value * 100).toFixed(fixed) + "%";
  }
  var _default = Ember.Helper.helper(formatPercentage);
  _exports.default = _default;
});