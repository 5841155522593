define("@agari/ember-shared/helpers/plus-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.plusOne = _exports.default = void 0;
  var plusOne = function plusOne(params) {
    return params[0] + 1;
  };
  _exports.plusOne = plusOne;
  var _default = Ember.Helper.helper(plusOne);
  _exports.default = _default;
});