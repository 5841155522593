define("@agari/ember-shared/helpers/abs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abs = abs;
  _exports.default = void 0;
  /**
   * absolute value of a number
   * @module abs
   * @param {Number, String} num value to format
   * @return {String} absolute value
   **/
  function abs(params) {
    var val = params[0];
    if (typeof val !== "number") {
      val = parseInt(val, 10);
    }
    return Math.abs(val);
  }
  var _default = Ember.Helper.helper(abs);
  _exports.default = _default;
});