define("ember-animated/templates/components/animated-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OJ7qqodu",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"items\"]]],[[\"key\",\"rules\",\"use\",\"duration\",\"group\",\"watch\",\"initialInsertion\",\"finalRemoval\"],[[27,[24,0],[\"key\"]],[27,[24,0],[\"rules\"]],[27,[24,0],[\"use\"]],[27,[24,0],[\"duration\"]],[27,[24,0],[\"group\"]],[27,[24,0],[\"watch\"]],[27,[24,0],[\"initialInsertion\"]],[27,[24,0],[\"finalRemoval\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,2,[[27,[24,1],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"animated-each\"]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-value.hbs"
    }
  });
  _exports.default = _default;
});