define("@agari/ember-shared/components/collapsible-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * A component to expand/collapse content
  
  ```hbs
    {{#collapsible-container title='Click Me for a Surprise!'}}
      <div class='inside-target'>
        surrebut soluble revocation esotropia whistlingly nonmastery ihi
        superdirection unsanitated pliosaurian unsliding uromelanin unpreach
        Ghedda piecemealwise danda
      </div>
    {{/collapsible-container}}
  ```
  *
  * @class CollapsibleContainer
  * @extends {Component}
  **/
  var _default = Ember.Component.extend({
    classNames: ["collapsible-container"],
    /**
      Value indicating whether content is collapsed or not
      @argument collapsed
      @type {Boolean}
    */
    collapsed: true,
    /**
      Value indicating whether header (trigger) is shown or not
      @argument hideHeader
      @type {Boolean}
    */
    hideHeader: false,
    /**
      action that toggles state when trigger is clicked
      @method toggleCollapse
      @type {Function}
    */
    actions: {
      toggleCollapse: function toggleCollapse() {
        var currentState = this.collapsed;
        this.set("collapsed", !currentState);
      }
    }
  });
  _exports.default = _default;
});