define("@agari/ember-shared/helpers/is-empty-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function (params) {
    return params[0] === "";
  });
  _exports.default = _default;
});