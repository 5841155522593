define("@agari/ember-shared/helpers/upcase", ["exports", "@agari/ember-shared/helpers/to-string"], function (_exports, _toString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.upcase = upcase;
  function upcase(params) {
    return (0, _toString.toString)(params).toUpperCase();
  }
  var _default = Ember.Helper.helper(upcase);
  _exports.default = _default;
});