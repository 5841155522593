define("@agari/ember-shared/services/sentry", ["exports", "ember-cli-sentry/services/raven"], function (_exports, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _raven.default.extend({
    _hushErrors: false,
    captureException: function captureException() {
      this._super.apply(this, arguments);
    } /* error */,
    captureMessage: function captureMessage() {
      return this._super.apply(this, arguments);
    } /* message */,
    enableGlobalErrorCatching: function enableGlobalErrorCatching() {
      return this._super.apply(this, arguments);
    },
    ignoreError: function ignoreError() {
      return this._hushErrors;
    },
    unmute: function unmute() {
      this._hushErrors = false;
      window.console.debug("unmute sentry");
    },
    mute: function mute() {
      this._hushErrors = true;
      window.console.debug("mute sentry");
    },
    setup: function setup() {
      return this._super.apply(this, arguments);
    },
    callRaven: function callRaven() {
      return this._super.apply(this, arguments);
    } /* methodName, ...optional */
  });
  _exports.default = _default;
});