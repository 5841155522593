define("@agari/ember-shared/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.capitalize = void 0;
  var capitalize = function capitalize(params) {
    return String(params[0]).toLowerCase().capitalize();
  };
  _exports.capitalize = capitalize;
  var _default = Ember.Helper.helper(capitalize);
  _exports.default = _default;
});