define("@agari/ember-shared/components/tab-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * A component to coordinate a group of TabControl components.
  
  * @class TabControls
  * @extends {Component}
  *
  */
  var _default = Ember.Component.extend({
    tagName: "ag-tabs",
    /**
     * Set this to the tab you'd like to be active. Usually it is bound to a
     * controller property that is used as a query parameter, but can be bound to
     * anything.
     *
     * @argument 'selected-index'
     * @type {String}
     */

    "selected-index": "",
    /**
     * The action to invoke when a tab is clicked. Receives the
     * tab index as a param.
     * @argument action
     * @type {Function}
     */

    actions: {
      tabClick: function tabClick(index) {
        this.set("selected-index", index);
        this.action(index);
      }
    }
  });
  _exports.default = _default;
});