define("@agari/ember-shared/helpers/pretty-date", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prettyDate = _exports.default = _exports.dateFormatter = void 0;
  var dateFormatter = function dateFormatter(format, dateObject, hash) {
    if (!dateObject && "default" in hash) {
      return hash.default;
    }
    if ((hash || {}).displayLocal) {
      return _momentTimezone.default.utc(dateObject).tz(_momentTimezone.default.tz.guess()).format(format);
    }
    return _momentTimezone.default.utc(dateObject).format(format);
  };
  _exports.dateFormatter = dateFormatter;
  var prettyDate = function prettyDate(params, hash) {
    return dateFormatter("D-MMM-YYYY", params[0], hash);
  };
  _exports.prettyDate = prettyDate;
  var _default = Ember.Helper.helper(prettyDate);
  _exports.default = _default;
});