define("ember-sortable/templates/components/sortable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IsClgku8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,1,\"CallHead\"],[]],null,[[\"handle\"],[[31,22,9,[27,[26,0,\"CallHead\"],[]],[\"ember-sortable@sortable-handle\"],null]]]]]],[1,1,0,0,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-sortable/templates/components/sortable-item.hbs"
    }
  });
  _exports.default = _default;
});