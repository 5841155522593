define("@agari/ember-shared/components/result-flare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CLEAR_FLARE_CALLBACK_DELAY = 500;
  var DEFAULT_TIMEOUT = 10000;
  var SET_INSERTED_STATE_DELAY = 250;

  /**
  * A component that displays messages in a small colored flare with icons. For `success`
  * messages, auto-hides the message after a certain interval. For `error` messages,
  * displays the message until provided with a new message or the user clicks a close
  * button.
  
  ```hbs
    <ResultFlare
      clearFlare=(action 'clearFlare' 'myFlare')
      flare=myFlare
      timeout=5000
    />
  ```
  
  *
  * @class ResultFlare
  * @extends {Component}
  */
  var _default = Ember.Component.extend({
    insertState: "pre-insert",
    classNames: ["result-flare-group"],
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      if (this.get("flare.message")) {
        var flare = this.flare;
        var timeout = this.timeout || DEFAULT_TIMEOUT;
        this.set("insertState", "pre-insert");
        Ember.run.later(this, function () {
          _this.set("insertState", "inserted");
        }, SET_INSERTED_STATE_DELAY);
        if (this.get("flare.type") === "success") {
          Ember.run.later(this, function () {
            if (!_this.isDestroyed && Ember.isEqual(_this.flare, flare)) {
              _this.set("insertState", "destroyed");
              Ember.run.later(_this, function () {
                _this.clearFlare();
              }, CLEAR_FLARE_CALLBACK_DELAY);
            }
          }, timeout);
        }
      } else {
        this.set("insertState", "pre-insert");
      }
    },
    clearFlare: function clearFlare() {},
    /**
      Object containing a `message` string and a type (`success` or `error`).
       e.g. `const myFlare = { message: 'Hello world!', type: 'success' }`
       @argument flare
      @type {object}
    */
    /**
      Optional integer to control how long a `success` flare stays visible before
      auto-hiding. The default is `10000` milliseconds.
       @argument timeout
      @type {Number}
    */
    /**
      Action called when the user clicks the close button on an error flare.
       e.g. `clearFlare() { this.set('myFlare', {}); }`
       @argument clearFlare
      @type {function}
    */
    actions: {
      onClose: function onClose() {
        this.clearFlare();
        this.set("insertState", "closed");
      }
    }
  });
  _exports.default = _default;
});