define("@agari/ember-shared/helpers/pretty-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.prettyObject = prettyObject;
  function prettyObject(params) {
    return JSON.stringify(params[0], null, 2);
  }
  var _default = Ember.Helper.helper(prettyObject);
  _exports.default = _default;
});