define("@agari/ember-shared/components/dialogs/simple-message", ["exports", "@agari/ember-shared/components/dialogs"], function (_exports, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _dialogs.default.extend({
    innerTemplate: "dialogs/simple-message",
    title: Ember.computed("model.title", function () {
      var title = this.get("model.title");
      return title ? title : "Confirm";
    })
  });
  _exports.default = _default;
});