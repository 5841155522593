define("@agari/ember-shared/authenticators/rails", ["exports", "ember-simple-auth/authenticators/devise"], function (_exports, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // XXX @rsullivan - I ran into a few weird issues when trying to
  // convert this to class syntax. Auth would just break with no
  // or cryptic errors. I did not have the time to debug. My hunch
  // is that its because we are on v1.6.0 of ember-simple auth.  We
  // may not be able to use class syntax until we update the library.
  var _default = _devise.default.extend({
    serverTokenEndpoint: "/api/account/login",
    tokenAttributeName: "auth_token",
    restore: function restore(data) {
      var options = {
        credentials: "same-origin"
      };
      return fetch("/api/account/heartbeat.json", options).then(function (response) {
        if (response.ok) {
          return Ember.RSVP.Promise.resolve(data);
        }
        return Ember.RSVP.Promise.reject();
      });
    }
  });
  _exports.default = _default;
});