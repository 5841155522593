define("@agari/ember-shared/helpers/pretty-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.prettyNumber = prettyNumber;
  function prettyNumber(params) {
    if (params.length === 0) {
      return null;
    }
    var value = params[0];
    if (typeof value !== "number") value = parseInt(value, 10);

    // Multiply and divide by 10 to ensure 1 decimal place
    value = Math.floor(value * 10) / 10;
    return isNaN(value) ? null : value.toLocaleString();
  }
  var _default = Ember.Helper.helper(prettyNumber);
  _exports.default = _default;
});