define("@agari/ember-shared/components/array-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A component that displays a string array as a one-column table with paging, sort direction,
   * and multi-item selection
  
  ```hbs
    {{array-table
      friendlyItemName='Widgets',
      selectionHandler=(action 'deleteAction')
      model=listOfStrings
      pageSize=15,
      submitButtonClass='trash-alt',
    }}
  ```
  *
  * @class ArrayTable
  * @extends {Component}
  */
  var _default = Ember.Component.extend({
    classNames: "array-table",
    /**
      Action called when button is clicked. Receives array of selected strings.
      @argument selectionHandler
      @type {Function}
    */
    /**
      Array of strings to be displayed and managed.
      @argument model
      @type {EmberArray}
    */
    /**
      Label shown when items are selected. Must be singular. Default `Item`.
      @argument friendlyItemName
      @type {String}
    */
    friendlyItemName: "Item",
    /**
      Value that determines current page number.
      @property pageNumber
      @type {Number}
    */
    pageNumber: 0,
    /**
      Value that determines the number of items displayed per page. Default `10`.
      @argument pageSize
      @type {Number}
    */
    pageSize: 10,
    /**
      Are all items selected?
      @property selectAll
      @type {Boolean}
    */
    selectAll: false,
    /**
      Value that determines the current sort direction. Default `DESC`.
      @property sortDirection
      @type {String}
    */
    sortDirection: "DESC",
    /**
      CSS class for button that submits selected items to handler. Default `trash-alt`.
      @argument submitButtonClass
      @type {String}
    */
    submitButtonClass: "trash-alt",
    internalModel: Ember.computed("model", "sortDirection", function () {
      var internalModel = this.model.map(function (item) {
        return {
          value: item,
          selected: false
        };
      }).sortBy("value");
      return this.sortDirection === "DESC" ? internalModel : internalModel.reverseObjects();
    }),
    pageMeta: Ember.computed("internalModel.[]", "pageSize", "pageNumber", function () {
      var pageSize = this.pageSize;
      var totalCount = this.internalModel.length;
      var start = this.pageNumber * pageSize;
      var end = start + pageSize;
      return {
        start: start,
        end: end,
        hasPrev: start - pageSize >= 0,
        hasNext: end < totalCount,
        label: "".concat(start + 1, "-").concat(Math.min(end, totalCount), " of ").concat(totalCount)
      };
    }),
    selectedValues: Ember.computed("internalModel.@each.selected", function () {
      return this.internalModel.filterBy("selected", true).mapBy("value");
    }),
    actions: {
      submitSelectedItems: function submitSelectedItems() {
        this.selectionHandler(this.selectedValues);
      },
      cancelSelection: function cancelSelection() {
        this.set("selectAll", false);
        this.internalModel.setEach("selected", false);
      },
      nextPage: function nextPage() {
        if (this.pageMeta.hasNext) {
          this.incrementProperty("pageNumber");
        }
      },
      prevPage: function prevPage() {
        if (this.pageMeta.hasPrev) {
          this.decrementProperty("pageNumber");
        }
      },
      selectAll: function selectAll() {
        this.toggleProperty("selectAll");
        this.internalModel.setEach("selected", this.selectAll);
      },
      toggleSortDirection: function toggleSortDirection() {
        this.set("sortDirection", this.sortDirection === "DESC" ? "ASC" : "DESC");
      }
    }
  });
  _exports.default = _default;
});