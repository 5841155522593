define("ember-animated/-private/ember-internals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentNodes = componentNodes;
  _exports.keyForArray = keyForArray;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _ref = Ember.ViewUtils,
    getViewBounds = _ref.getViewBounds;
  function componentNodes(view) {
    var bounds = getViewBounds(view);
    return {
      firstNode: bounds.firstNode,
      lastNode: bounds.lastNode
    };
  }
  function keyForArray(keyPath) {
    switch (keyPath) {
      case '@index':
        return index;
      case '@identity':
      case undefined:
      case null:
        return identity;
      default:
        return function (item) {
          return Ember.get(item, keyPath);
        };
    }
  }
  function index(_item, index) {
    return String(index);
  }
  function identity(item) {
    switch (_typeof(item)) {
      case 'string':
      case 'number':
        return String(item);
      default:
        return Ember.guidFor(item);
    }
  }
});