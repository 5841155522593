define("@agari/ember-shared/helpers/pretty-json", ["exports", "@agari/ember-shared/helpers/pretty-object"], function (_exports, _prettyObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.prettyJson = prettyJson;
  function prettyJson(params) {
    try {
      var json = JSON.parse(params[0] || "{}");
      return (0, _prettyObject.prettyObject)([json]);
    } catch (err) {
      return (0, _prettyObject.prettyObject)([{}]);
    }
  }
  var _default = Ember.Helper.helper(prettyJson);
  _exports.default = _default;
});