define("@agari/ember-shared/helpers/pretty-date-time", ["exports", "@agari/ember-shared/helpers/pretty-date"], function (_exports, _prettyDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prettyDateTime = _exports.default = void 0;
  var prettyDateTime = function prettyDateTime(params, hash) {
    return (0, _prettyDate.dateFormatter)("D-MMM-YYYY H:mm:ss z", params[0], hash);
  };
  _exports.prettyDateTime = prettyDateTime;
  var _default = Ember.Helper.helper(prettyDateTime);
  _exports.default = _default;
});