define("ember-animated/templates/components/animated-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8d9xe0oz",
    "block": "{\"symbols\":[\"Tag\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[31,77,7,[27,[26,1,\"CallHead\"],[]],[[31,77,7,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"tag\"]]],null]],[[\"tagName\"],[[27,[24,0],[\"tag\"]]]]]],null,[[\"default\"],[{\"statements\":[[7,[27,[24,1],[]],[[14,\"class\",[32,[\"animated-container \",[27,[24,2],[]]]],null],[15,3]],[[],[]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[16,4,null],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-element\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-container.hbs"
    }
  });
  _exports.default = _default;
});