define("@agari/ember-shared/helpers/to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toString = toString;
  function toString(params) {
    var value = params[0];
    if (Ember.isNone(value)) {
      return "";
    }
    if (value.constructor === {}.constructor) {
      return JSON.stringify(value, null, " ");
    }
    return value.toString();
  }
  var _default = Ember.Helper.helper(toString);
  _exports.default = _default;
});