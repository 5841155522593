define("@agari/ember-shared/components/tab-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * A tab for use in a tab group.
  
  * @class TabControl
  * @extends {Component}
  *
  */
  var _default = Ember.Component.extend({
    tagName: "ag-tab",
    attributeBindings: ["role", "aria-selected", "aria-expanded", "selected", "data-test"],
    /**
     * See http://www.w3.org/TR/wai-aria/roles#tab
     *
     * @property role
     * @type String
     * @private
     */

    role: "tab",
    /**
     * The index for this tab, should be the same format as the
     * `TabControls` `selected-index`.
     * @argument index
     * @type {String}
     */
    index: "",
    /**
     * The `TabControls` component that this tab lives inside.
     * @argument tabContainer
     * @type {Component}
     */
    tabContainer: null,
    /**
     * Sets the [selected] attribute on the element when this tab is active.
     * Makes sure to remove the attribute completely when not selected.
     *
     * @property selected
     * @type Boolean
     */

    selected: Ember.computed("active", function () {
      return this.active ? "selected" : null;
    }),
    /**
     * Makes the selected tab keyboard tabbable, also prevents tabs from getting
     * focus when clicked with a mouse.
     *
     * @property tabindex
     * @type Number
     */

    tabindex: Ember.computed("active", function () {
      return this.active ? 0 : null;
    }),
    /**
     * Tells screenreaders whether or not this tab is selected.
     *
     * @property 'aria-selected'
     * @type String
     * @private
     */

    "aria-selected": Ember.computed("active", function () {
      // coerce to ensure a "true" or "false" attribute value
      return this.active + "";
    }),
    /**
     * Tells screenreaders whether or not this tabs panel is expanded.
     *
     * @property 'aria-expanded'
     * @type String
     * @private
     */

    "aria-expanded": Ember.computed.alias("aria-selected"),
    /**
     * Whether or not this tab is selected.
     *
     * @property active
     * @type Boolean
     */

    active: Ember.computed("tabContainer.selected-index", "index", function () {
      return this.get("tabContainer.selected-index") === this.index;
    }),
    click: function click() {
      this.tabContainer.send("tabClick", this.index);
    }
  });
  _exports.default = _default;
});