define("@agari/ember-shared/helpers/pretty-spf-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function (params) {
    var map = {
      "+": "Pass",
      n: "None",
      "-": "Fail",
      "~": "SoftFail",
      "?": "Neutral",
      p: "PermError",
      t: "TempError"
    };
    return map[params[0]] || "Unknown";
  });
  _exports.default = _default;
});