define("@agari/ember-shared/utils/calculate-risk-params", ["exports", "@agari/ember-shared/cousteauConstants"], function (_exports, _cousteauConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateRiskParams = calculateRiskParams;
  /**
   * A function to calculate message risk parameters.
   *
   * @param {Number} untrusted threshold for untrusted
   * @param {Number} risky threshold for risky
   * @param {Number} messageRisk the message's actual risk
   * @return {message_risk_min: Number, message_risk_max: Number}
   */
  function calculateRiskParams(_ref) {
    var untrusted = _ref.untrusted,
      risky = _ref.risky,
      messageRisk = _ref.messageRisk;
    var _CousteauConstants$ME = _cousteauConstants.default.MESSAGE,
      RISK_MAX = _CousteauConstants$ME.RISK_MAX,
      RISK_MIN = _CousteauConstants$ME.RISK_MIN;
    var riskParams;
    if (messageRisk >= risky) {
      // trusted bucket
      riskParams = {
        message_risk_min: risky,
        message_risk_max: RISK_MAX
      };
    } else if (messageRisk < untrusted) {
      // untrusted bucket
      riskParams = {
        message_risk_min: RISK_MIN,
        message_risk_max: untrusted
      };
    } else {
      // suspicious bucket
      riskParams = {
        message_risk_min: untrusted,
        message_risk_max: risky
      };
    }
    return riskParams;
  }
});