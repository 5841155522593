define("@agari/ember-shared/helpers/array-slice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arraySlice = arraySlice;
  _exports.default = void 0;
  function arraySlice(params) {
    return params[0].slice(params[1], params[2]);
  }
  var _default = Ember.Helper.helper(arraySlice);
  _exports.default = _default;
});