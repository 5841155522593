define("@agari/ember-shared/components/button-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * A component that toggles its state when clicked
  
  ```hbs
    {{button-switch
      offLabel='toggle off'
      onLabel='toggle on'
      toggleValue=thingToToggle
      onChange=buttonAction
    }}
  ```
  *
  * @class ButtonSwitch
  * @extends {Component}
  */
  var _default = Ember.Component.extend({
    tagName: "button",
    classNames: "button-switch",
    classNameBindings: ["isOn:on:off", "disabled"],
    attributeBindings: ["data-test"],
    /**
      Value indicating whether button is switched on or not
      @argument toggleValue
      @type {Boolean}
    */
    /**
      Value indicating whether button is switched on or not
      @property isOn
      @type {Boolean}
      @readOnly
    */
    isOn: Ember.computed.oneWay("toggleValue"),
    /**
      Label for "off" state
      @argument offLabel
      @type {String}
    */
    offLabel: "",
    /**
      Label for "on" state
      @argument onLabel
      @type {String}
    */
    onLabel: "",
    /**
      Is button clickable
      @argument disabled
      @type {Boolean}
    */
    disabled: false,
    /**
      action to call when button is clicked
      @argument onChange
      @type {Function}
    */
    /**
      action to call when button is clicked
      @method click
      @type {Function}
    */
    click: function click(event) {
      if (this.disabled) return;
      var isOn = this.isOn;
      if (event.target.classList.contains("off-label") && !isOn || event.target.classList.contains("on-label") && isOn) {
        return;
      }
      this.onChange(!isOn);
    }
  });
  _exports.default = _default;
});