define("@agari/ember-shared/helpers/nvl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nvl = _exports.default = void 0;
  var nvl = function nvl(frozen_params) {
    var params = frozen_params.slice();
    while (params.length) {
      var value = params.shift();
      if (value) return value;
    }
    return null;
  };
  _exports.nvl = nvl;
  var _default = Ember.Helper.helper(nvl);
  _exports.default = _default;
});