define("ember-animated/templates/components/animated-each", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yiYWnA6s",
    "block": "{\"symbols\":[\"child\",\"&else\",\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"renderedChildren\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"ea-list-element\",[],[[\"@child\",\"@elementToChild\"],[[27,[24,1],[]],[27,[24,0],[\"_elementToChild\"]]]],[[\"default\"],[{\"statements\":[[16,3,[[27,[24,1],[\"value\"]],[27,[24,1],[\"index\"]]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[16,2,null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-each.hbs"
    }
  });
  _exports.default = _default;
});