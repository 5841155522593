define("@agari/ember-shared/helpers/string-eq", ["exports", "ember-truth-helpers/helpers/eq"], function (_exports, _eq) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringEq = _exports.default = void 0;
  var stringEq = function stringEq(frozen_params) {
    return (0, _eq.eq)(frozen_params.slice().map(function (item) {
      return (item || "").toString();
    }));
  };
  _exports.stringEq = stringEq;
  var _default = Ember.Helper.helper(stringEq);
  _exports.default = _default;
});