define("@agari/ember-shared/helpers/route-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.routeMatch = routeMatch;
  function routeMatch(args) {
    var currentPath = args[0];
    var calculatedPath = args[1];
    if (Ember.isEmpty(currentPath) || Ember.isEmpty(calculatedPath)) {
      return false;
    }
    return currentPath.indexOf(calculatedPath) >= 0;
  }
  var _default = Ember.Helper.helper(routeMatch);
  _exports.default = _default;
});