define("ember-animated/templates/components/animated-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2e0eeqOi",
    "block": "{\"symbols\":[\"currentPredicate\",\"&else\",\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,0],[\"predicate\"]]],[[\"key\",\"rules\",\"use\",\"duration\",\"group\",\"initialInsertion\",\"finalRemoval\"],[[27,[24,0],[\"key\"]],[27,[24,0],[\"rules\"]],[27,[24,0],[\"use\"]],[27,[24,0],[\"duration\"]],[27,[24,0],[\"realGroup\"]],[27,[24,0],[\"initialInsertion\"]],[27,[24,0],[\"finalRemoval\"]]]],[[\"default\"],[{\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,1],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,3,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[16,2,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"animated-value\"]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-if.hbs"
    }
  });
  _exports.default = _default;
});