define("@agari/ember-shared/utils/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildUrlWithQueryParams = buildUrlWithQueryParams;
  _exports.objectToParamString = objectToParamString;
  function objectToParamString(obj) {
    var paramArray = [];
    for (var key in obj) {
      paramArray.push("".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(obj[key])));
    }
    return paramArray.join("&");
  }
  function buildUrlWithQueryParams(url) {
    var paramsObject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (paramsObject && Object.keys(paramsObject).length) {
      var queryString = objectToParamString(paramsObject);
      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += queryString;
    }
    return url;
  }
});