define("@agari/ember-shared/helpers/downcase", ["exports", "@agari/ember-shared/helpers/to-string"], function (_exports, _toString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.downcase = downcase;
  function downcase(params) {
    return (0, _toString.toString)(params).toLowerCase();
  }
  var _default = Ember.Helper.helper(downcase);
  _exports.default = _default;
});