define("@agari/ember-shared/helpers/pretty-duration", ["exports", "ember-inflector", "moment-timezone"], function (_exports, _emberInflector, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.prettyDuration = prettyDuration;
  /**
   * A helper to turn durations into formatted strings.
   * Default behavior very basic, designed for whole unit durations
   * of > 1 minute and < 1 day.
   * Specify an output unit for more control over the formatted string.
   * @module prettyDuration
   * @param {Number} num value to format
   * @param {String} string units to use in initialization, e.g. 'seconds', 'days'
   * @param {String} string (optional) units to output, e.g. 'seconds', 'days'
   * @return {String} formatted string
   **/
  function prettyDuration(params) {
    var result = params[0];
    var duration = _momentTimezone.default.duration(params[0], params[1]);
    if (params[2]) {
      var unit = params[2].capitalize().slice(0, -1);
      result = "".concat((0, _emberInflector.pluralize)(duration["as".concat(unit, "s")](), unit));
    } else {
      var days = Math.abs(duration.days());
      var hours = Math.abs(duration.hours());
      var minutes = Math.abs(duration.minutes());
      if (days) {
        result = "".concat((0, _emberInflector.pluralize)(days, "Day"));
      } else if (hours) {
        result = "".concat((0, _emberInflector.pluralize)(hours, "Hour"));
      } else if (minutes) {
        result = "".concat((0, _emberInflector.pluralize)(minutes, "Minute"));
      }
    }
    return result;
  }
  var _default = Ember.Helper.helper(prettyDuration);
  _exports.default = _default;
});