define("ember-sortable/templates/components/sortable-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xpYJ70wc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,12,4,[27,[26,4,\"CallHead\"],[]],null,[[\"item\",\"model\"],[[31,27,9,[27,[26,3,\"CallHead\"],[]],[\"ember-sortable@sortable-item\"],[[\"direction\",\"registerItem\",\"deregisterItem\",\"setSelectedItem\",\"update\",\"prepare\",\"commit\",\"activateKeyDown\",\"deactivateKeyDown\"],[[27,[26,2,\"Expression\"],[]],[31,114,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"registerItem\"],null],[31,159,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"deregisterItem\"],null],[31,207,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"setSelectedItem\"],null],[31,247,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"update\"],null],[31,279,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"prepare\"],null],[31,311,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"commit\"],null],[31,351,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"activateKeyDown\"],null],[31,402,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"deactivateKeyDown\"],null]]]],[27,[26,0,\"Expression\"],[]]]]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"direction\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-sortable/templates/components/sortable-group.hbs"
    }
  });
  _exports.default = _default;
});